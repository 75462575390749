import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Articles from "../components/articles"
import Banner from "../components/banner";

const FeaturePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="連載" keywords={[`連載`, `働き方`, `リモートデスクトップ`, `時短`]} />
      <Articles title="連載一覧" data={data.articles}/>
      <Banner/>
    </Layout>
  );
}

export default FeaturePage;

export const query = graphql`
query {
    articles: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { 
        fields: {
          reserved: { eq: false },
          attributes: { in: ["series"] },
          first: { eq: true }
        }
      }) {
      edges {
        node {
          id
          fields {
            date: series_date(formatString: "YYYY年M月D日")
            author
            thumbnail {
              childImageSharp {
                fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
            title: series_title
            slug: series_path
            summary
            attributes
            first
            tags
          }
          excerpt
        }
      }
    }  
  } 
`
